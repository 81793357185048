import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDistributions } from '../api/distributionsApi';
import { setDistributionsList } from '../slices/distributionsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getDistributionsList = createAsyncThunk('distributions/getDistributionsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getDistributions(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setDistributionsList({ distributions: data.data.distributions })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
