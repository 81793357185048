import { Box, Grid2 } from "@mui/material";
import React from "react";
import DistributionCapitalGraph from "./DistributionCapitalGraph";
import DistributionRecordBarGraph from "./DistributionRecordBarGraph";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function ProjectGraphsSection() {
  const distributions = useSelector((state) => state.distributions.list);
  let { id } = useParams();
  const current = useSelector((state) => state.investments.current);
  const investments = Object.values(current);
  const investmentToShow = investments.find((i) => i._id === id);

  console.log({ distributions });
  return (
    <Box component={"section"} my={2}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12, lg: 6 }}>
          <DistributionRecordBarGraph
            startProyectYear={
              new Date(investmentToShow?.investmentDate)?.getFullYear() ?? 2020
            }
            data={
              distributions
                .map((elemt) => {
                  return {
                    name: elemt?.period ?? "",
                    capital: elemt?.netDistribution ?? 0,
                    date: elemt?.date,
                  };
                })
                ?.reverse() ?? []
            }
          />
        </Grid2>
        <Grid2 size={{ xs: 12, lg: 6 }}>
          <DistributionCapitalGraph
            startProyectYear={
              new Date(investmentToShow?.investmentDate)?.getFullYear() ?? 2020
            }
            data={
              distributions
                .map((elemt) => {
                  return {
                    name: elemt?.period ?? "",
                    "Distribución neta acumulada": elemt?.netAccumulated ?? 0,
                    "Capital aportado": investmentToShow?.capitalNow || 0,
                    date: elemt?.date,
                  };
                })
                ?.reverse() ?? []
            }
          />
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default ProjectGraphsSection;
