import { Grid, useMediaQuery } from "@mui/material";

const styles = {
  footer: {
    px: { xs: "1rem", md: "4rem" },
    py: { xs: ".3rem", md: ".6rem" },
    backgroundColor: "#3C3C3B",
    color: "secondary.contrastText",
  },
};

const AppFooter = () => {
  const isSmallScreen = useMediaQuery("(min-width:600px)");

  return (
    <Grid
      container
      sx={styles.footer}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={4}
        textAlign={{ xs: "center", md: "left" }}
        display="flex"
        justifyContent={{ xs: "center", md: "left" }}
        alignItems="center"
      >
        <img
          src="/img/IDEWHITE.png"
          alt="IDE logo"
          style={{ width: isSmallScreen ? "50px" : "40px" }}
        />
      </Grid>
      <Grid item xs={12} md={8} textAlign={{ xs: "center", md: "right" }}>
        {/* <Typography
          variant={isSmallScreen ? "caption" : "caption"}
          component={"p"}
          textAlign={{ xs: "center", md: "right" }}
        >
          {isSmallScreen ? (
            <>
              Av. Paseo de la Reforma No. 2620, Edificio Reforma Plus, PH2,
              Colonia Lomas Altas,
              <br />
              C.P.11950, Alcaldía Miguel Hidalgo, Ciudad de México.México
              <br />
            </>
          ) : null}
          Contacto: fbraun@ide.fund
        </Typography> */}
        <a
          style={{ border: "none", cursor: "pointer" }}
          onClick={() => {
            window.open(
              "https://secure.ssl.com/team/ad4-1hunsje/site_seals/bd6a9abe-63ebf26d/site_report",
              "site_report",
              "height=500, width=400, top=100, left=100"
            );
            return false;
          }}
          href="https://secure.ssl.com/team/ad4-1hunsje/site_seals/bd6a9abe-63ebf26d/site_report"
        >
          <img
            width="64px"
            src="https://securesslcom.a.cdnify.io/assets/ssl_seal_1-3dfcdf69a9e544495dbcf366a74ee5c4baca7e4f8018f7ddfa22f2de9bcf464d.png"
            alt="Ssl seal 1"
          />
        </a>
      </Grid>
    </Grid>
  );
};

export default AppFooter;
