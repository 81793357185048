import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "../../pages/Login/Login";
import Dashboard from "../../pages/Dashboard/Dashboard";
import ProjectsPage from "../../pages/Dashboard/Projects/ProjectsPage";
import Reportes from "../../pages/Dashboard/Projects/ProjectDetails/Reportes";
import Forgot from "../../pages/Login/forgotpassword";
import Reset from "../../pages/Login/restablecerpass";
import ProfilePage from "../../pages/Dashboard/Profile/ProfilePage";
import ContactPage from "../../pages/Dashboard/Contact/ContactPage";
import ChangePasswordPage from "../../pages/Dashboard/Profile/ChangePassword/ChangePasswordPage";
import ProjectDetailsPageNew from "../../pages/Dashboard/Projects/ProjectDetails/Views/ProjectDetailsPageNew";
import InformativeReportsPage from "../../pages/Dashboard/Reports/Informative/InformativeReportsPage";
import FinantialReportsPage from "../../pages/Dashboard/Reports/Finantial/FinantialReportsPage";
import ReportPageByID from "../../pages/Dashboard/Projects/ProjectDetails/Reports/ReportPageByID";
import ProjectReportPageByID from "../../pages/Dashboard/Projects/ProjectDetails/Reports/ProjectReportPageByID";
import { useEffect } from "react";
function LocationRoutes({ token }) {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    console.log(pathname);
    if (pathname === "/login" || pathname.includes("login")) {
      console.log("No guardar ruta");
      return;
    }
    if (pathname.includes("forgotpassword")) {
      return;
    }
    window.localStorage.setItem("lastRoute", pathname);
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={token ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route path="/login/forgotpassword" element={<Forgot />} />
      <Route path="/login/restablecerpass" element={<Reset />} />
      <Route path="/reportes" element={<Reportes />} />
      <Route path="/dashboard" element={<ProjectsPage />} />
      <Route
        path="/dashboard/*"
        element={token ? <Dashboard /> : <Navigate to="/login" />}
      >
        <Route index element={<Navigate to="proyectos" />} />
        <Route path="proyectos" element={<ProjectsPage />} />

        <Route path="contacto" element={<ContactPage />} />
        <Route path="perfil" element={<ProfilePage />} />
        <Route
          path="perfil/cambio-contraseña"
          element={<ChangePasswordPage />}
        />
        <Route
          path="reportes-informativos"
          element={<InformativeReportsPage />}
        />
        <Route
          path="reportes-informativos/:id/reportes"
          element={<Navigate to="proyectos/reportes-informativos" />}
        />
        <Route
          path="reportes-informativos/:id/reportes/:reportId"
          element={<ProjectReportPageByID />}
        />
        <Route path="reportes-financieros" element={<FinantialReportsPage />} />
        <Route
          path="reportes-financieros/:id/reportes"
          element={<Navigate to="proyectos/reportes-financieros" />}
        />
        <Route
          path="reportes-financieros/:id/reportes/:reportId"
          element={<ProjectReportPageByID />}
        />
        <Route path="proyectos/:id" element={<ProjectDetailsPageNew />} />
        <Route
          path="proyectos/:id/reportes"
          element={<Navigate to="proyectos/:id" />}
        />
        <Route
          path="proyectos/:id/reportes/:reportId"
          element={<ReportPageByID />}
        />
        <Route path="*" element={<Navigate to="proyectos" />} />
      </Route>
      <Route
        path="*"
        element={
          token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
        }
      />
    </Routes>
  );
}

export default LocationRoutes;
