import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import ReportItem from "../../../../components/items/ReportItem";

const styles = {
  container: {
    display: "block",
  },
  content: {
    paddingY: 0,
    height: 525,
    width: "80vw",
  },
  actions: {
    paddingRight: "1rem",
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    px: "1rem",
  },
};

const ProjectDetailsReports = (props) => {
  const [year, setYear] = React.useState("año");

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const reports = useSelector((state) => state.reports.list);

  const handleClose = (e) => {
    e?.preventDefault();
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Reportes informativos</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={{ pb: "1rem" }}>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label="year"
              onChange={handleChange}
              sx={{
                marginTop: "1.5em",
                marginBottom: "1.5em",
                width: "240px",
                borderRadius: "32px",
              }}
            >
              <MenuItem value={"año"}>año</MenuItem>
              {[
                ...new Set(
                  reports
                    .filter((report) => report.type === "informativo")
                    .map((r) => new Date(r.date).getFullYear())
                ),
              ].map((y) => {
                return (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>

            <TableContainer
              component={Paper}
              style={{ backgroundColor: "#ffffff", borderRadius: 30 }}
            >
              <Table>
                <TableHead
                  sx={{ backgroundColor: "#3E6779", borderRadius: 20 }}
                >
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }}>
                      Nombre del reporte
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Reporte</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>Periodo</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      Fecha del reporte
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {year === "año"
                    ? reports
                        .filter((report) => report.type === "informativo")
                        .map((report) => (
                          <ReportItem
                            key={report._id}
                            {...report}
                            filter="informativo"
                          />
                        ))
                    : reports
                        .filter((report) => {
                          if (
                            report.type === "informativo" &&
                            new Date(report.date).getFullYear() === year
                          ) {
                            return true;
                          }
                          return false;
                        })
                        .map((report) => (
                          <ReportItem
                            key={report._id}
                            {...report}
                            filter="informativo"
                          />
                        ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetailsReports;
