import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid2";
import { Box, Grid2, Typography } from "@mui/material";
import ProjectsFilter from "./components/ProjectsFilter";
// import AppPagination from "../../../components/AppPagination";

import { resetProjectsState } from "../../../slices/projectsSlice";
import { getInvestmentsList } from "../../../actions/investmentsActions";
import ProjectsPieChart from "./components/ProjectsPieChart";
import ProjectsListTablePerCurrency from "./components/ProjectsListTablePerCurrency";
// import { LinkRounded } from "@mui/icons-material";

const defaultFilter = {
  page: 1,
  project: "",
};

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.investments.list);

  // const currency = useSelector((state) => state.currency);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetProjectsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.project.length > 0 ? (f.project = filter.project) : null;

    dispatch(getInvestmentsList({ query: f }));
  }, [pagination]);

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setPagination({ page: 1 });
  };

  const onClear = () => setFilter({ ...defaultFilter });

  // const nextPage = () => setPagination({ page: pagination.page + 1 });
  // const prevPage = () =>
  //   setPagination({ page: Math.max(pagination.page - 1, 1) });

  console.log({ projects });

  return (
    <>
      <Box px={"1rem"}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }} my={2}>
          <Typography variant="h4" fontWeight="bold" sx={{ color: "#3C3C3B" }}>
            Inversiones
          </Typography>
        </Box>
        <ProjectsFilter
          {...filter}
          onChange={handleChange}
          onSubmit={onSubmit}
          onClear={onClear}
        />
        <Grid2 container spacing={2} justifyContent={"center"}>
          <Grid2 size={{ xs: 12, xl: 6 }}>
            <ProjectsListTablePerCurrency
              isUSD={true}
              projects={projects.filter(
                (element) => element.projectRef.isInvestmentUSD
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, xl: 6 }}>
            <ProjectsListTablePerCurrency
              isUSD={false}
              projects={projects.filter(
                (element) => !element.projectRef.isInvestmentUSD
              )}
            />
          </Grid2>
        </Grid2>

        <Grid container my={3} spacing={2}>
          <ProjectsPieChart
            title="Capital invertido USD"
            isUSD={true}
            data={projects
              .filter((element) => element.projectRef.isInvestmentUSD)
              .map((element) => {
                return {
                  name: element.projectRef?.name ?? "Inversión",
                  value: element.projectRef?.isInvestmentUSD
                    ? element?.capital ?? 0
                    : element?.capital ?? 0,
                  investmentDate: element.projectRef?.investmentDate,
                };
              })}
          />
          <ProjectsPieChart
            title={"Capital invertido MXN"}
            data={projects
              .filter((element) => !element.projectRef.isInvestmentUSD)
              .map((element) => {
                return {
                  name: element.projectRef?.name ?? "Inversión",
                  value: element.projectRef?.isInvestmentUSD
                    ? element?.capital ?? 0
                    : element?.capital ?? 0,
                  investmentDate: element.projectRef?.investmentDate,
                };
              })}
          />
        </Grid>
      </Box>
    </>
  );
};

export default ProjectsPage;
