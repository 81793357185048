import { getReports } from "./reportsApi";

export const requestReportsApi = (token, query) =>
  new Promise((resolve, reject) => {
    getReports(token, query)
      .then((response) =>
        response.status === 200 ? response.json() : reject(response)
      )
      .then((data) => {
        resolve(data.reports);
      });
  });
