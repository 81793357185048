import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Typography,
} from "@mui/material";
import { HomeWork } from "@mui/icons-material";

import { logout } from "../actions/authActions";

const items = [
  { text: "Proyectos", icon: <HomeWork />, path: "/dashboard/proyectos" },
];

const styles = {
  drawer: {
    width: 300,
    flex: 1,
  },
  logoWrapper: {
    justifyContent: "center",
  },
  logo: {
    width: "60%",
    cursor: "pointer",
  },
  dividerWrapper: {
    marginY: "0.75rem",
    paddingX: "1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "1rem",
  },
};

const AppDrawer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (path) => () => {
    navigate(path);
    props.onClose();
  };

  const handleLogout = () => {
    window.localStorage.removeItem("lastRoute");
    dispatch(logout({ callback: () => navigate("/login") }));
  };

  return (
    <Drawer {...props}>
      <Box sx={styles.drawer} role="presentation">
        <List>
          <ListItem sx={styles.logoWrapper}>
            <img
              src="/img/IDEGREY.png"
              alt="IDE logo"
              style={styles.logo}
              onClick={handleNavigate("")}
            />
          </ListItem>
          <Box sx={styles.dividerWrapper}>
            <Divider />
          </Box>
          {items.map((item) => (
            <ListItemButton key={item.text} onClick={handleNavigate(item.path)}>
              {/* <ListItemIcon>
                {item.icon}
              </ListItemIcon> */}
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    display="block"
                    gutterBottom
                    sx={{
                      color: "gray",
                      ":hover": {
                        color: "#0B1F2E",
                      },
                    }}
                  >
                    {item.text}
                  </Typography>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button fullWidth variant="text" onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </Box>
    </Drawer>
  );
};

export default AppDrawer;
