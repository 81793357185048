import React from "react";
import ProjectDetailsDistributions from "../../../ProjectDetailsDistributions";

function DistrubutionSection() {
  return (
    <section>
      <ProjectDetailsDistributions></ProjectDetailsDistributions>
    </section>
  );
}

export default DistrubutionSection;
