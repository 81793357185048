import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import ProjectsListItemPerCurrency from "./ProjectsListItemPerCurrency";
import ProjectsTotalPerCurrency from "./ProjectsTotalPerCurrency";

const ProjectsListTablePerCurrency = (props) => {
  if (props.projects.length < 1) {
    return <></>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ mb: 2 }}
      style={{ backgroundColor: "#ffffff", borderRadius: "0.75rem" }}
    >
      <Table>
        <TableHead sx={{ backgroundColor: "#3E6779" }}>
          <TableRow sx={{ borderBottom: "2px solid" }}>
            <TableCell
              sx={{
                textAlign: "left",
                width: {
                  xs: "calc(100%/4)",
                },
              }}
            >
              Proyecto
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                width: {
                  xs: "calc(100%/5)",
                },
              }}
            >
              Nombre
            </TableCell>
            <TableCell
              sx={{
                textAlign: "center",
                width: {
                  xs: "calc(100%/6)",
                  sm: "calc(100%/7)",
                  md: "calc(100%/9)",
                },
              }}
            >
              Fecha de inversión
            </TableCell>

            <TableCell
              sx={{
                textAlign: "center",
                width: {
                  xs: "calc(100%/6)",
                  sm: "calc(100%/7)",
                  md: "calc(100%/8)",
                },
              }}
            >
              {`Participación en el activo`}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "right",
                width: {
                  xs: "calc(100%/5)",
                  sm: "calc(100%/6)",
                  md: "calc(100%/7)",
                },
              }}
            >
              {` Monto de Inversión ${!!props?.isUSD ? "USD" : "MXN"}`}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.projects?.map((project) => (
            <ProjectsListItemPerCurrency key={project._id} {...project} />
          ))}
          <ProjectsTotalPerCurrency projects={props.projects} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectsListTablePerCurrency;
