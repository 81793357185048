import { Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function CardAccordion({ children, title }) {
  return (
    <Accordion elevation={4} defaultExpanded sx={{ borderRadius: ".75rem" }}>
      <AccordionSummary
        sx={{ borderRadius: "1rem", mb: 0 }}
        expandIcon={<ArrowDropDownIcon />}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ borderRadius: "1rem" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

export default CardAccordion;
