import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forgotPassword } from "../../actions/authActions";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
  },
  bgImage: {
    backgroundImage: "url(/img/IDENEW2.jpg)",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "590px",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "right",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    height: "100%",
    width: "auto",
    objectFit: "cover",
    marginBottom: "2rem",
  },
  resize: {
    fontSize: 80,
  },

  textfieldr: {
    borderRadius: 40,
    [`& fieldset`]: {
      borderRadius: 80,
    },
  },
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#0B1F2E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0B1F2E",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.55) inset",
      borderRadius: "1rem",
      borderColor: "#0B1F2E",
    },
    "&:hover fieldset": {
      borderColor: "#0B1F2E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0B1F2E",
    },
  },
});

const Forgot = () => {
  const [state, setState] = useState({ user: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userError, setUserError] = useState("");

  const resetErrors = () => {
    setTimeout(() => {
      setUserError("");
      setUserError("");
    }, 2000);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log("HANDLE SUBMIT");
    e.preventDefault();
    if (state.user === "") {
      setUserError("Usario no valido");
    }
    resetErrors();
    if (state.user === "") {
      return;
    }
    dispatch(forgotPassword({ ...state, callback: handleLogin }));
  };

  const handleLogin = () => {
    navigate("/login/restablecerpass");
  };

  return (
    <Box sx={styles.container}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11} sm={8} md={7} lg={5} sx={styles.form}>
          <Box sx={{ px: { xs: "2rem", md: "5rem" } }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={6} md={6} lg={6}>
                <Box
                  alt="IDE Fund Logo"
                  sx={{
                    height: 150,
                    width: "auto",
                    backgroundImage: "url(/img/IDEGREY.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></Box>
                {/* <img alt="IDE Fund Logo" src="/img/IDE.png" style={styles.logo} />   */}
              </Grid>

              <Grid
                item
                xs={10}
                container
                justifyContent={"center"}
                padding={2}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid item xs={11} textAlign="center" marginBottom={2}>
                  <Typography variant="h4">Recupera tu contraseña</Typography>
                </Grid>
                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Usuario</Typography>
                </Grid>

                <CssTextField
                  disabled={false}
                  placeholder="usuario"
                  name="user"
                  value={state.user}
                  onChange={handleChange}
                  error={userError !== "" ? true : false}
                  helperText={userError !== "" ? userError : ""}
                  variant="outlined"
                />

                <Grid item xs={11} textAlign="center">
                  <Button style={styles.button} type="submit">
                    <Typography variant="h6"> Recuperar</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Forgot;
