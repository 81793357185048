import { createAsyncThunk } from '@reduxjs/toolkit';
import { createProject, createProjectInterest, getProject, getProjectInterests, getProjects, updateProject, uploadProjectDescriptionImage, uploadProjectImage } from '../api/projectsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentProject, setProjectsList } from '../slices/projectsSlice';
import { setInterestsList } from '../slices/interestsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getProjectsList = createAsyncThunk('projects/getProjectsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProjects(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setProjectsList({ projects: data.projects.map(project => project.projectRef) })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentProject = createAsyncThunk('projects/getCurrentProject', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProject(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProject({ project: data.project })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewProject = createAsyncThunk('projects/createNewProject', ({ project, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createProject(token, project)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.project_id))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentProject = createAsyncThunk('projects/updateCurrentProject', ({ id, project }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateProject(token, id, project)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProject({ project: data.project })))
  .then(() => dispatch(showAlert({ message: 'Proyecto actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentProjectInterests = createAsyncThunk('projects/getCurrentProjectInterests', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProjectInterests(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setInterestsList({ interests: data.payments })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewProjectInterest = createAsyncThunk('projects/createNewProjectInterest', ({ id, interest, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createProjectInterest(token, id, interest)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadCurrentProjectImage = createAsyncThunk('projects/uploadCurrentProjectImage', ({ id, file }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadProjectImage(token, id, file)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(getCurrentProject({ id })))
  .then(() => dispatch(showAlert({ message: 'Imagen subida', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadCurrentProjectDescriptionImage = createAsyncThunk('projects/uploadCurrentProjectDescriptionImage', ({ id, file }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadProjectDescriptionImage(token, id, file)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(getCurrentProject({ id })))
  .then(() => dispatch(showAlert({ message: 'Imagen subida', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

