import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, useMediaQuery } from "@mui/material";
import ProjectDetailsReports from "../ProjectDetailsReports";
import ProjectDetailsFinanceInfo from "../ProjectDetailsFinanceInfo";
import ProjectDetailsInvestorReports from "../ProjectDetailsInvestorReports";

import { resetProjectsState } from "../../../../../slices/projectsSlice";
import {
  getInvertorReportsList,
  getReportsList,
} from "../../../../../actions/reportsActions";
import { getCurrentInvestment } from "../../../../../actions/investmentsActions";
import { getDistributionsList } from "../../../../../actions/distributionsActions";
import { getInterestsList } from "../../../../../actions/interestsActions";
import { ProjectAppbar } from "../../ProjectAppbar";
import ProjectDetailSection from "./Sections/ProjectDetailSection";
import ProjectGraphsSection from "./Sections/Graphs/ProjectGraphsSection";
import DistrubutionSection from "./Sections/Tables/DistrubutionSection";
import InterestSection from "./Sections/Tables/InterestSection";

const ProjectDetailsPageNew = () => {
  const { id: investmentId } = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.investments.current);

  const [modals, setModals] = useState({
    reports: false,
    finance: false,
    investor: false,
  });

  useEffect(() => {
    dispatch(getCurrentInvestment({ id: investmentId }));
    return () => dispatch(resetProjectsState());
  }, []);

  useEffect(() => {
    if (current[0]?.projectRef?._id) {
      dispatch(
        getDistributionsList({
          query: {
            projectRef: current[0].projectRef._id,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getInterestsList({
          query: {
            projectRef: current[0].projectRef._id,
            associateInvestmentId: investmentId,
          },
        })
      );
      dispatch(
        getReportsList({ query: { projectRef: current[0].projectRef._id } })
      );
      dispatch(
        getInvertorReportsList({
          investorId: current[0].associateRef?._id,
          projectId: current[0].projectRef._id,
          associateInvestmentId: investmentId,
        })
      );
    }
  }, [current]);

  const handleOpenModal = (modal) => setModals({ ...modals, [modal]: true });
  const handleCloseModal = (modal) => setModals({ ...modals, [modal]: false });

  const isSmallScreen = useMediaQuery("(min-width:600px)");

  /*
  const handleInfoReport = () => {
    handleOpenModal("reports");
  };

  const handleFinancialInfo = () => {
    handleOpenModal("finance");
  };
*/
  return (
    <>
      {isSmallScreen && (
        <ProjectAppbar
          investments={current}
          onOpen={handleOpenModal}
          isUSD={current[0]?.projectRef?.isUSD}
        />
      )}
      {/*!isSmallScreen && (
        <Grid2
          container
          justifyContent={"center"}
          bgcolor={"#0B1F2E"}
          padding="0 0"
        >
          <Grid2 size={6}>
            <Button fullWidth variant="text" onClick={handleInfoReport}>
              <Typography variant="subtitle2" color="white" component="div">
                Reportes Informativos
              </Typography>
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Button fullWidth variant="text" onClick={handleFinancialInfo}>
              <Typography variant="subtitle2" color="white" component="div">
                Información Financiera
              </Typography>
            </Button>
          </Grid2>
        </Grid2>
      )
      */}
      <ProjectDetailsReports
        open={modals.reports}
        onClose={() => handleCloseModal("reports")}
      />
      <ProjectDetailsFinanceInfo
        open={modals.finance}
        onClose={() => handleCloseModal("finance")}
      />
      <ProjectDetailsInvestorReports
        open={modals.investor}
        onClose={() => handleCloseModal("investor")}
        distributionId={""}
      />
      <Container maxWidth="xl">
        <ProjectDetailSection />
        <ProjectGraphsSection />
        <InterestSection />
        <DistrubutionSection />
      </Container>
    </>
  );
};

export default ProjectDetailsPageNew;
