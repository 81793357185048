import { ChevronLeftSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ChangePasswordForm from "./ChangePasswordForm";

function ChangePasswordPage() {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography variant="h4" fontWeight={"bold"} my={2}>
        Cambiar contraseña
      </Typography>
      <Box display={"flex"} justifyContent={""}>
        <Button
          variant="text"
          startIcon={<ChevronLeftSharp />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
      </Box>
      <Grid2 container justifyContent={"left"} spacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
          <Card elevation={2} sx={{ borderRadius: "1rem" }}>
            <CardContent>
              <ChangePasswordForm />
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default ChangePasswordPage;
