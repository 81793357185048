import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  current: {},
};

const interestsSlice = createSlice({
  name: "interests",
  initialState,
  reducers: {
    setInterestsList: (state, { payload }) => ({
      ...state,
      list: [...(payload.interests?.reverse() ?? [])],
    }),
    setCurrentInterest: (state, { payload }) => ({
      ...state,
      current: { ...payload.interest },
    }),
    resetInterestsState: () => ({ list: [], current: {} }),
  },
});

export const { setInterestsList, setCurrentInterest, resetInterestsState } =
  interestsSlice.actions;
export default interestsSlice.reducer;
