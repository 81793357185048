import { Box } from "@mui/material";

function PdfComp({ pdfDir }) {
  return (
    <Box sx={{ borderRadius: 10, my: 2 }}>
      <iframe title="report" src={pdfDir} width="100%" height="500px" />
    </Box>
  );
}

export default PdfComp;
