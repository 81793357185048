import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProfilePage() {
  const user = useSelector((state) => state.auth.me);
  const navigate = useNavigate();

  const handleButton = () => {
    navigate("/dashboard/perfil/cambio-contraseña");
  };

  console.log({ user });

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" fontWeight={"bold"} my={2}>
        Usuario
      </Typography>
      <Grid2 container justifyContent={"left"} spacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
          <Card elevation={4} sx={{ borderRadius: "1rem" }}>
            <CardContent>
              <Box display={"flex"}>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  flex={1}
                  flexGrow={1}
                  mb={2}
                >
                  Información de contacto
                </Typography>
                {/* <Button color="secondary" sx={{ my: 0, borderRadius: "0.5rem" }}>
              Editar
            </Button> */}
              </Box>
              <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                Nombre:
                <Typography
                  variant="body1"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.name ?? "-"}
                </Typography>
              </Typography>
              <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                Usuario:
                <Typography
                  variant="body1"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.user ?? "-"}
                </Typography>
              </Typography>
              <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                Correo:{" "}
                <Typography
                  variant="body1"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.email ?? "-"}
                </Typography>
              </Typography>
              <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                Teléfono:{" "}
                <Typography
                  variant="body1"
                  component={"span"}
                  fontWeight={"regular"}
                  ml={1}
                >
                  {user?.phoneNumber ?? "-"}
                </Typography>
              </Typography>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Grid2 container justifyContent={"left"} spacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
          <Box display={"flex"} justifyContent={"end"}>
            <Button onClick={handleButton}>Cambiar contraseña</Button>
          </Box>
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default ProfilePage;
