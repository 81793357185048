import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Card, Typography } from "@mui/material";
import AppPagination from "../../../../components/AppPagination";
// import { useNavigate } from 'react-router-dom';
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Menu } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { resetProjectsState } from "../../../../slices/projectsSlice";
import { getInvestmentsList } from "../../../../actions/investmentsActions";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InformativosFilter from "../ProjectDetails/InformativosFilter";
import InformativoListTable from "./InformativoListTable";
import AppDrawer from "../../../../components/AppDrawer";
import AppFooter from "../../../../components/AppFooter";

const drawerWidth = 180;

const defaultFilter = {
  page: 1,
  project: "",
};

const Reportes = (props) => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);
  // const navigate = useNavigate();

  /*const navigateToContacts = () => {
    // 👇️ navigate to /
    navigate('/reportes');
  };*/
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.investments.list);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetProjectsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.project.length > 0 ? (f.project = filter.project) : null;

    dispatch(getInvestmentsList({ query: f }));
  }, [pagination]);

  const handleChange = (e) =>
    setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    setPagination({ page: 1 });
  };

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () =>
    setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Card>
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              sx={{ mr: 1.5 }}
              onClick={openDrawer}
            >
              <Menu />
            </IconButton>
            <Typography variant="h5" color="inherit" component="div">
              IDE Fund Admin
            </Typography>
          </Toolbar>
        </AppBar>
        <AppDrawer open={open} onClose={closeDrawer} />
        <Box
          component="main"
          sx={{ py: "0rem", px: { xs: "1rem", md: "5rem" } }}
        >
          <Outlet />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={8}>
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ marginBottom: "1rem", color: "#006064" }}
            >
              Reportes Informativos
            </Typography>
          </Grid>
        </Box>
        <InformativosFilter
          {...filter}
          onChange={handleChange}
          onSubmit={onSubmit}
          onClear={onClear}
        />
        <InformativoListTable projects={projects} />
        <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
        <AppDrawer open={open} onClose={closeDrawer} />
        <Box
          component="main"
          sx={{ py: "2rem", px: { xs: "1rem", md: "5rem" } }}
        >
          <Outlet />
        </Box>
        <AppFooter />
      </Card>
    </>
  );
};

export default Reportes;
