import { TableRow, TableCell, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../../utils/formatUtils";

const ProjectsListItemPerCurrency = (props) => {
  return (
    <TableRow>
      <TableCell sx={{ textAlign: "left" }}>
        <Link to={props._id}>{props.projectRef?.name}</Link>
      </TableCell>
      <TableCell sx={{ textAlign: "left" }}>
        <Link to={props._id}>{props.investor}</Link>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {new Date(props.projectRef?.investmentDate)?.toLocaleString("es-Mx", {
          month: "short",
          year: "numeric",
        })}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {props.participation ?? "-"}%
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Box display={"flex"}>
          <Typography variant="body2" pl={8}>
            {" "}
            $
          </Typography>
          <Typography variant="body2" flex={1} flexGrow={1}>
            {
              currencyFormatter
                .format(props.capital)
                .replace("$", "")
                .split(".")[0]
            }
          </Typography>
        </Box>
        {/* {currencyFormatter.format(props.capital).split(".")[0]} */}
      </TableCell>
    </TableRow>
  );
};

export default ProjectsListItemPerCurrency;
