import { Grid2, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getTenYears } from "../../../utils/getTenyears";

const years = getTenYears();

const ages = [
  {
    value: "Todos",
    label: "Todos",
  },
  ...years.map((data) => {
    return {
      value: data,
      label: data.toString(),
    };
  }),
];

function ReportsFilter({ localTotalReports, setLocalReports, investments }) {
  const prevlocalInvestments = [
    {
      value: "Todos",
      label: "Todos",
    },
    ...investments.map((investor) => {
      return {
        value: investor?.projectRef._id,
        label: investor?.projectRef?.name ?? "Inversion",
      };
    }),
  ];

  const localInvestments = [
    ...new Map(prevlocalInvestments.map((item) => [item.value, item])).values(),
  ];

  const [selectedAge, setSelectedAge] = useState("Todos");
  const [selectedInvestmet, setSelectedInvestmet] = useState("Todos");

  const handleAgeChange = (event) => {
    setSelectedAge(event.target.value);
  };

  const handleInvertChange = (event) => {
    setSelectedInvestmet(event.target.value);
  };

  useEffect(() => {
    const totalReports = [
      ...new Map(localTotalReports.map((item) => [item._id, item])).values(),
    ];
    if (selectedAge === "Todos" && selectedInvestmet === "Todos") {
      setLocalReports(totalReports);
      return;
    }
    if (selectedInvestmet === "Todos") {
      const mySelectedAgeData = totalReports.filter((element) => {
        const date = new Date(element.date).getFullYear();
        return date === selectedAge;
      });
      setLocalReports(mySelectedAgeData);
      return;
    }
    if (selectedAge === "Todos") {
      const mySelectedReportData = totalReports.filter((element) => {
        const selectInvestValue = localInvestments.find((myInvest) => {
          return myInvest.label === selectedInvestmet;
        }).value;
        return element.projectRef === selectInvestValue;
      });
      setLocalReports(mySelectedReportData);
      return;
    }

    const mySelectedReportData = totalReports.filter((element) => {
      const selectInvestValue = localInvestments.find((myInvest) => {
        return myInvest.label === selectedInvestmet;
      }).value;
      return element.projectRef === selectInvestValue;
    });
    const mySelectedAgeData = mySelectedReportData.filter((element) => {
      const date = new Date(element.date).getFullYear();
      return date === selectedAge;
    });
    setLocalReports(mySelectedAgeData);
  }, [selectedAge, selectedInvestmet]);

  return (
    <Grid2 container spacing={{ xs: 2, md: 4 }}>
      <Grid2 size={6}>
        <TextField
          select
          label="Proyectos"
          disabled={false}
          value={selectedInvestmet}
          onChange={handleInvertChange}
          sx={{ py: 0 }}
        >
          {localInvestments.map((option, index) => (
            <MenuItem
              key={`${option.label}-invest-${index}`}
              value={option.label}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
      <Grid2 size={6}>
        <TextField
          select
          label="Fecha"
          disabled={false}
          value={selectedAge}
          onChange={handleAgeChange}
          sx={{ py: 0 }}
        >
          {ages.map((option) => (
            <MenuItem key={`${option.value}-age`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
    </Grid2>
  );
}

export default ReportsFilter;
