import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LocationRoutes from "./LocationRoutes";

const Router = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <BrowserRouter>
      <LocationRoutes token={token}></LocationRoutes>
    </BrowserRouter>
  );
};

export default Router;
