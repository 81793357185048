import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInterests } from '../api/interestsApi';
import { setInterestsList } from '../slices/interestsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getInterestsList = createAsyncThunk('interests/getInterestsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getInterests(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setInterestsList({ interests: data.payments })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
