import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import React, { useState } from "react";
import ReportsFilter from "./ReportsFilter";
import { Link } from "react-router-dom";

function ReportsSection({ data, investments }) {
  const [localReports, setLocalReports] = useState([...data]);

  console.log({ data, investments });

  const hoursToAdd = 6 * 60 * 60 * 1000;

  return (
    <Box my={2}>
      <ReportsFilter
        localTotalReports={data}
        investments={investments}
        setLocalReports={setLocalReports}
      />
      <Box component={"section"} my={2}>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#ffffff", borderRadius: "0.75rem" }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: "#3E6779", borderRadius: 10 }}>
              <TableRow>
                <TableCell style={{ minWidth: 120 }} align="left">
                  Nombre
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="left">
                  Proyecto
                </TableCell>
                <TableCell style={{ minWidth: 120 }} align="center">
                  Periodo
                </TableCell>
                <TableCell align="center">Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: "150px", overflowY: "auto" }}>
              {localReports.length < 1 ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    No hay reportes
                  </TableCell>
                </TableRow>
              ) : (
                localReports.map((report, index) => (
                  <TableRow key={`report-${report._id}-${index}`}>
                    <TableCell align="left">
                      {!!report?.path ? (
                        // <Link
                        //   sx={{ color: "#0B1F2E" }}
                        //   target="_blank"
                        //   href={`${REACT_APP_HOST_URL}/images/reports/${
                        //     report?.path ?? ""
                        //   }`}
                        // >
                        //   {report.name}
                        // </Link>
                        <Link
                          style={{ color: "#3E6779 !important" }}
                          sx={{ color: "#0B1F2E" }}
                          to={`${
                            investments.find(
                              (invest) =>
                                invest.projectRef._id === report.projectRef
                            )?.projectRef?._id ?? "NOP"
                          }/reportes/${report._id}`}
                          // href={`${REACT_APP_HOST_URL}/images/reports/${
                          //   reports.find(
                          //     (r) => r?.distributionRef === distribution._id
                          //   ).path
                          // }`}
                        >
                          Ver Reporte
                        </Link>
                      ) : (
                        <Box>Sin reporte</Box>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {investments.find(
                        (invest) => invest.projectRef._id === report.projectRef
                      )?.projectRef?.name ?? "Inversion"}
                    </TableCell>
                    <TableCell align="center">{report.period}</TableCell>
                    <TableCell align="center">
                      {new Date(
                        new Date(report.date).setTime(
                          new Date(report.date).getTime() + hoursToAdd
                        )
                      )?.toLocaleString("es-Mx", {
                        month: "short",
                        year: "numeric",
                      })}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ReportsSection;
