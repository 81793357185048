import { Grid2, Typography } from "@mui/material";
import React from "react";
import ProjectDetailsInfo from "../../ProjectDetailsInfo";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProjectDetailsDescription from "../../ProjectDetailsDescription";

function ProjectDetailSection() {
  const { id: investmentId } = useParams();
  const current = useSelector((state) => state.investments.current);

  return (
    <Grid2 container my={2} columnSpacing={2} rowGap={2}>
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            marginTop: "0.5rem",
            marginBottom: "-0.5rem",
            color: "#3C3C3B",
          }}
        >
          {current[0]?.projectRef?.name ?? investmentId}
        </Typography>
        <ProjectDetailsDescription {...current[0]?.projectRef} />
      </Grid2>
      <Grid2 size={{ xs: 12, lg: 6 }}>
        <ProjectDetailsInfo
          investments={current}
          isUSD={current[0]?.projectRef?.isUSD}
        />
      </Grid2>
    </Grid2>
  );
}

export default ProjectDetailSection;
