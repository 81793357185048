import { useSelector } from "react-redux";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { currencyFormatter } from "../../../../utils/formatUtils";

const ProjectDetailsInterests = () => {
  const interests = useSelector((state) => state.interests.list);
  const hoursToAdd = 6 * 60 * 60 * 1000;

  return (
    <>
      {interests.length > 0 ? (
        <>
          <Box my={2}>
            <Typography variant="h5">Pago de intereses</Typography>
          </Box>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "#ffffff", borderRadius: "2rem" }}
          >
            <Table>
              <TableHead sx={{ backgroundColor: "#3E6779", borderRadius: 20 }}>
                <TableRow>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Fecha
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Periodo
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Interés Neto
                  </TableCell>
                  <TableCell style={{ minWidth: 120 }} align="center">
                    Interés Neto Acumulado
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interests.map((interest) => (
                  <TableRow key={interest._id}>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {new Date(
                        new Date(interest.date).setTime(
                          new Date(interest.date).getTime() + hoursToAdd
                        )
                      ).toLocaleDateString("es-MX", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {interest.period}
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {
                        currencyFormatter
                          .format(interest.netInterest ?? 0)
                          .split(".")[0]
                      }
                    </TableCell>
                    <TableCell style={{ minWidth: 120 }} align="center">
                      {
                        currencyFormatter
                          .format(interest.netAccumulated ?? 0)
                          .split(".")[0]
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};

export default ProjectDetailsInterests;
