import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { currencyFormatter } from "../../../../utils/formatUtils";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

const styles = {
  inputWrapper3: {
    margin: "0.2rem 0rem",
    border: "2px solid #424242",
    backgroundColor: "#FFFFFF",
    borderRadius: "0.75rem",
    minHeight: 50,
    display: "flex",
    justifyContent: "left",
    alignItem: "center",
    padding: "0px 10px",
  },
};

const ProjectDetailsInfo = (props) => {
  let { id } = useParams();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const investments = Object.values(props.investments);
  const investmentToShow = investments.find((i) => i._id === id);
  const hoursToAdd = 6 * 60 * 60 * 1000;
  const date = new Date(investmentToShow?.investmentDate);

  return (
    <Card elevation={0}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
      </Box>
      <CardHeader
        subheaderTypographyProps={{ fontWeight: "bold" }}
        sx={{
          backgroundColor: "#0B1F2E",
          borderRadius: "0.75rem",
          padding: "0.5rem",
        }}
        title="Datos de la inversión"
      />
      <CardContent sx={{ px: 0 }}>
        <Grid2 container columnSpacing={2}>
          <Grid2 size={12}>
            <Box mt={1}>
              <Typography variant="body1" color="grey" fontSize={14}>
                Vehículo de inversión
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {investmentToShow?.vehiculo == null ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  ------------{" "}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {investmentToShow?.investor}
                </Typography>
              )}
            </Box>
            <Box mt={1}>
              <Typography color="grey" fontSize={14}>
                Capital por aportar
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  currencyFormatter
                    .format(investmentToShow?.capital)
                    .split(".")[0]
                }{" "}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography color="grey" fontSize={14}>
                Capital aportado a la fecha
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {
                  currencyFormatter
                    .format(investmentToShow?.capitalNow || 0)
                    .split(".")[0]
                }{" "}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography color="grey" fontSize={14}>
                Participación en el activo
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {investmentToShow?.participation?.toFixed(2) ?? ""} %{" "}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography color="grey" fontSize={14}>
                Fecha de inversión
              </Typography>
            </Box>
            <Box sx={styles.inputWrapper3}>
              {/* <Text>ㅤ</Text> */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {new Date(
                  date.setTime(date.getTime() + hoursToAdd)
                ).toLocaleDateString("es-MX", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                })}
              </Typography>
            </Box>
            {maxSteps > 1 && (
              <MobileStepper
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              />
            )}
          </Grid2>
          <Grid2 size={12} textAlign={"right"}>
            <Typography
              textAlign={"end"}
              sx={{
                fontStyle: "italic",
                marginRight: "1em",
              }}
            >
              {props.isUSD ? "*Montos en USD" : "*Montos en MXN"}
            </Typography>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
};

export default ProjectDetailsInfo;
