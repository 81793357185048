import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid2,
  Link,
  Typography,
} from "@mui/material";
import React from "react";

function ContactPage() {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" fontWeight={"bold"} my={2}>
        Contacto IDE
      </Typography>
      <Grid2 container justifyContent={"left"} spacing={2}>
        <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
          <Card elevation={8} sx={{ borderRadius: "1rem" }}>
            <CardContent>
              <Box>
                <Typography
                  variant="h6"
                  component={"p"}
                  fontWeight={"bold"}
                  mb={1}
                >
                  IDE, Ideas en Desarrollo
                </Typography>
                <Typography variant="body1" component={"p"} mb={1}>
                  Relacion con Inversionistas
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  fontWeight={"bold"}
                  mb={1}
                >
                  <Link href={"tel:5555206575"}>55 5520-6575</Link>
                </Typography>
                <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                  Contacto:{" "}
                  <Link href="mailto:fbraun@ide.fund">fbraun@ide.fund</Link>
                </Typography>
                <Typography
                  variant="body1"
                  component={"p"}
                  fontWeight={"bold"}
                  ml={8}
                  mb={1}
                >
                  <Link href="mailto:ibecerril@ide.fund">
                    ibecerril@ide.fund
                  </Link>
                </Typography>
                <Divider></Divider>
                <Typography variant="body1" component={"p"} fontWeight={"bold"}>
                  Dirección
                </Typography>
                <Typography variant="body1" component={"p"}>
                  Av. Paseo de la Reforma No. 2620, Edificio Reforma Plus, PH2,
                  Colonia Lomas Altas, C.P. 11950, Alcaldía Miguel Hidalgo,
                  Ciudad de México, México.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default ContactPage;
