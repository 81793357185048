export function getTenYears(startProjectYear) {
  const actualYear = new Date(Date.now()).getFullYear();
  const startIDE = new Date(startProjectYear ?? 2020, 11, 17).getFullYear();
  let yearsData = [];
  for (let i = 0; i <= actualYear - startIDE; i++) {
    yearsData.push(actualYear - i);
  }
  console.log({ yearsData });
  return yearsData;
}
