import { ThemeProvider } from "@mui/material/styles";
import Router from "./config/routes/Router";
import LoadingDialog from "./components/LoadingDialog";
import FeedbackAlert from "./components/FeedbackAlert";
import ErrorsDialog from "./components/ErrorsDialog";
import "./App.css";

import theme from "./config/theme";
import { useEffect } from "react";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

function App() {
  useEffect(() => {}, []);

  return (
    <ThemeProvider theme={theme}>
      <Router />
      <LoadingDialog />
      <FeedbackAlert />
      <ErrorsDialog />
    </ThemeProvider>
  );
}

export default App;
