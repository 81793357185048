export const currencyFormatter = Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export const currencyGraphFormatter = (data) => {
  const currency = Number(data) ?? 0;
  if (currency > -999 && currency <= 999) {
    return `$${currency}`;
  }
  if (currency >= 1000 && currency < 999999) {
    const value = currency / 1000;
    return `$${value.toFixed(0)}k`;
  }
  if (currency >= 1000000 && currency < 999999999) {
    const value = currency / 1000000;
    return `$${value.toFixed(0)}M`;
  }
};
