import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { logout } from "../actions/authActions";
import { LogoutTwoTone } from "@mui/icons-material";

const items = [
  // { text: "Proyectos", icon: <HomeWork />, path: "/dashboard/proyectos" },
  { text: "Inversiones", icon: <BusinessIcon />, path: "/dashboard/proyectos" },
  {
    text: "Reportes informativos",
    icon: <AssessmentIcon />,
    path: "/dashboard/reportes-informativos",
  },
  {
    text: "Información financiera",
    icon: <RequestQuoteIcon />,
    path: "/dashboard/reportes-financieros",
  },
  //{ text: "Otros", icon: <HomeWork />, path: "/dashboard/proyectos" },
  {
    text: "Contacto",
    icon: <ContactPhoneIcon />,
    path: "/dashboard/contacto",
  },
  {
    text: "Perfil usuario",
    icon: <AccountCircleIcon />,
    path: "/dashboard/perfil",
  },
];

const drawerWidth = 180;

const styles = {
  drawer: {
    width: drawerWidth - 5,
    flex: 1,
  },
  logoWrapper: {
    justifyContent: "center",
  },
  logo: {
    width: "75px",
    cursor: "pointer",
  },
  dividerWrapper: {
    marginY: "0.75rem",
    paddingX: "1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    padding: "1rem",
  },
};

const AppDrawerNew = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const { pathname } = location;

  const handleNavigate = (path) => () => {
    navigate(path);
    if (props?.isMobile ?? false) props.onClose();
  };

  const handleLogout = () => {
    window.localStorage.removeItem("lastRoute");
    dispatch(logout({ callback: () => navigate("/login") }));
  };

  console.log(pathname);
  return (
    <div>
      <Box sx={styles.drawer} role="presentation">
        <Box
          display={"flex"}
          flexDirection={"column"}
          height={"100vh"}
          width={"100%"}
        >
          <Box flex={1} flexGrow={1}>
            <List>
              <ListItem sx={styles.logoWrapper}>
                <img
                  src="/img/IDEGREY.png"
                  alt="IDE logo"
                  style={styles.logo}
                  onClick={handleNavigate("")}
                />
              </ListItem>
              <Box sx={styles.dividerWrapper}>
                <Divider />
              </Box>
              {items.map((item, index) => (
                <Box display={"flex"} height={55} key={`${item.text}-${index}`}>
                  <Box
                    width={8}
                    bgcolor={
                      pathname.includes(item.path) ? "#BE9961" : "inherit"
                    }
                    height={"100%"}
                    sx={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                  />
                  <ListItemButton
                    onClick={handleNavigate(item.path)}
                    sx={{ py: 1, flexGrow: 1 }}
                  >
                    <ListItemIcon
                      sx={{
                        color: pathname.includes(item.path)
                          ? "#BE9961"
                          : "gray",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ my: 0 }}
                      primary={
                        <Typography
                          my={0}
                          variant="body1"
                          display="block"
                          gutterBottom
                          sx={{
                            fontWeight: pathname.includes(item.path)
                              ? "bold"
                              : "normal",
                            color: pathname.includes(item.path)
                              ? "#0B1F2E"
                              : "gray",
                            ":hover": {
                              color: "#0B1F2E",
                            },
                          }}
                        >
                          {item.text}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </Box>
              ))}
            </List>
          </Box>
          <Box width={"100%"}>
            <ListItemButton selected onClick={handleLogout}>
              <ListItemIcon>
                <LogoutTwoTone></LogoutTwoTone>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    display="block"
                    my={0}
                    gutterBottom
                    sx={{
                      color: "gray",
                      ":hover": {
                        color: "#0B1F2E",
                      },
                    }}
                  >
                    Cerrar sesión
                  </Typography>
                }
              />
            </ListItemButton>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AppDrawerNew;
