import { Typography, Card, CardContent, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import ProjectDetailsImagesCarousel from "./ProjectDetailsImagesCarousel";
import DialogTitle from "@mui/material/DialogTitle";
import { currencyFormatter } from "../../../../utils/formatUtils";
import { useSelector } from "react-redux";
import ProjectDesciptionButton from "./Views/components/ProjectDesciptionButton";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Grid container justifyContent="left">
        <Grid item>{children}</Grid>
      </Grid>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProjectDetailsDescription = (props) => {
  const current = useSelector((state) => state.investments.current);

  return (
    <>
      <Card elevation={0}>
        <CardContent sx={{ px: 0 }}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ marginTop: "0.5rem", color: "#9e9e9e" }}
          >
            Valor de adquisición
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            sx={{ marginBottom: "1rem" }}
          >
            <img src="/img/money.png" alt="money" />
            {
              currencyFormatter
                .format(current[0]?.projectRef?.investment)
                .split(".")[0]
            }
          </Typography>
          <ProjectDetailsImagesCarousel images={props.images} />
          <ProjectDesciptionButton />
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectDetailsDescription;
