import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { login } from "../../actions/authActions";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
  },
  bgImage: {
    backgroundImage: "url(/img/IDENEW2.jpg)",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "right",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    height: "100%",
    width: "auto",
    objectFit: "cover",
    marginBottom: "2rem",
  },
  resize: {
    fontSize: 80,
  },

  textfieldr: {
    borderRadius: 40,
    [`& fieldset`]: {
      borderRadius: 80,
    },
  },
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#0B1F2E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0B1F2E",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.55) inset",
      borderRadius: "1rem",
      borderColor: "#0B1F2E",
    },
    "&:hover fieldset": {
      borderColor: "#0B1F2E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0B1F2E",
    },
  },
});

const Login = () => {
  const [state, setState] = useState({ user: "", password: "" });
  const [rememberDevice, setRememberDevice] = useState(() => {
    const remember = window.localStorage.getItem("remember");
    console.log("Remember");
    console.log(remember);
    console.log(typeof remember);
    if (remember) {
      if (remember === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userError, setUserError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () =>
    setShowPassword((showPassword) => !showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const resetErrors = () => {
    setTimeout(() => {
      setUserError("");
      setPasswordError("");
    }, 2000);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.user === "") {
      setUserError("Usario no valido");
    }
    if (state.password === "") {
      setPasswordError("Constraseña no valido");
    }
    resetErrors();
    if (state.password === "" || state.user === "") {
      return;
    }
    dispatch(login({ ...state, callback: handleLogin }));
  };

  const handleLogin = () => {
    const lastRoute = window.localStorage.getItem("lastRoute");
    console.log(lastRoute);
    if (!!lastRoute) {
      console.log("A ultima ruta");
      navigate(lastRoute);
      return;
    }
    console.log("Al inicio");
    navigate("/dashboard");
  };

  return (
    <Box sx={styles.container}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={11} sm={8} md={7} lg={5} sx={styles.form}>
          <Box sx={{ px: { xs: "2rem", md: "5rem" } }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={6} md={6} lg={6} my={2}>
                <Box
                  alt="IDE Fund Logo"
                  sx={{
                    height: 150,
                    width: "auto",
                    backgroundImage: "url(/img/IDEGREY.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></Box>
                {/* <img alt="IDE Fund Logo" src="/img/IDE.png" style={styles.logo} />   */}
              </Grid>

              <Grid
                item
                xs={10}
                container
                justifyContent={"center"}
                padding={2}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid item xs={11} textAlign="center" marginBottom={2}>
                  <Typography variant="h4">Iniciar sesión</Typography>
                </Grid>
                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Usuario</Typography>
                </Grid>

                <CssTextField
                  disabled={false}
                  placeholder="usuario"
                  name="user"
                  value={state.user}
                  onChange={handleChange}
                  error={userError !== "" ? true : false}
                  helperText={userError !== "" ? userError : ""}
                  variant="outlined"
                />
                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Contraseña</Typography>
                </Grid>
                <CssTextField
                  disabled={false}
                  placeholder="contraseña"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  error={passwordError !== "" ? true : false}
                  helperText={passwordError !== "" ? passwordError : ""}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid item xs={11} textAlign="center">
                  <Button style={styles.button} type="submit">
                    <Typography variant="h5"> Entrar</Typography>
                  </Button>
                </Grid>
                <Grid item xs textAlign="Left">
                  <FormControl
                    sx={{ marginTop: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberDevice}
                            onChange={(e) => {
                              if (!window.localStorage.getItem("remember")) {
                                console.log("no hubo");
                                window.localStorage.setItem("remember", true);
                              } else {
                                console.log("hubo");
                                window.localStorage.setItem(
                                  "remember",
                                  window.localStorage.getItem("remember") ===
                                    "true"
                                    ? false
                                    : true
                                );
                              }
                              setRememberDevice((v) => !v);
                            }}
                            name="gilad"
                          />
                        }
                        label="Recordar en este dispositivo"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  color="#000000"
                  sx={{ height: 40, width: "3px" }}
                />
                <Grid item xs textAlign="Right">
                  <Link href="/login/forgotpassword">
                    <br /> Olvidé mi contraseña
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
