import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentInvestment } from "../../../../../actions/investmentsActions";
import { resetProjectsState } from "../../../../../slices/projectsSlice";
import { Container, Grid2, Typography } from "@mui/material";
import { getCurrentReport } from "../../../../../actions/reportsActions";

import PdfComp from "./PdfComp";

const { REACT_APP_HOST_URL } = process.env;

function ReportPageByID() {
  const { id: investmentId, reportId } = useParams();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.investments.current);
  const currentReport = useSelector((state) => state.reports.current);

  useEffect(() => {
    dispatch(getCurrentInvestment({ id: investmentId }));
    dispatch(getCurrentReport({ id: reportId }));
    return () => dispatch(resetProjectsState());
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid2 container my={2}>
        <Grid2 size={{ xs: 12, md: 8 }}>
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              marginTop: "0.5rem",
              marginBottom: "-0.5rem",
              color: "#3C3C3B",
            }}
          >
            {current[0]?.projectRef?.name ?? investmentId}
          </Typography>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 4 }}>
          {/* <Typography
            variant="h4"
            fontWeight="bold"
            sx={{
              marginTop: "0.5rem",
              marginBottom: "-0.5rem",
              color: "#3C3C3B",
            }}
          >
            {currentReport?.name ?? investmentId}
          </Typography> */}
        </Grid2>
      </Grid2>
      <PdfComp
        pdfDir={`${REACT_APP_HOST_URL}/images/reports/${currentReport.path}`}
      />
    </Container>
  );
}

export default ReportPageByID;
