const { REACT_APP_API_BASE_URL } = process.env;

export const loginAdmin = (user, password) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/associates/login", {
      method: "POST",
      body: JSON.stringify({ user, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const forgotPasswordApi = (user) =>
  new Promise((resolve, reject) => {
    console.log("CALL FORGOTPASSWORDApi");
    const request = new Request(REACT_APP_API_BASE_URL + "/associates/forgot", {
      method: "POST",
      body: JSON.stringify({ user }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const forgotPasswordChangeApi = (user, token, newPassword) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/associates/forgot-change-password",
      {
        method: "POST",
        body: JSON.stringify({ user, token, newPassword }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const changePasswordApi = (token, currentPassword, newPassword) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_API_BASE_URL + "/associates/change-password",
      {
        method: "PUT",
        body: JSON.stringify({ currentPassword, newPassword }),
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }
    );

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const logoutAdmin = (token) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/associates/logout", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });

export const getMe = (token) =>
  new Promise((resolve, reject) => {
    const request = new Request(REACT_APP_API_BASE_URL + "/associates/me", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    fetch(request)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
