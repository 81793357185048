import { useState } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
// import { Menu } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

import AppFooter from "../../components/AppFooter";
import { getmeactions } from "../../actions/authActions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { onMessage } from "firebase/messaging";
import {
  generateTokenMessaging,
  messaging,
} from "../../services/firebaseNotifications";
import { updateToken } from "../../actions/notificationsActions";
import AppDrawerNew from "../../components/AppDrawerNew";

const drawerWidth = 180;

const Dashboard = () => {
  const dispatch = useDispatch();

  const { me, token } = useSelector((state) => state.auth);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    dispatch(getmeactions());
  }, []);

  useEffect(() => {
    const isSupported = () =>
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window;
    if (token && me?._id && isSupported && window?.Notification) {
      window?.Notification.requestPermission()
        .then(() => {
          generateTokenMessaging()
            .then((fcmToken) => {
              // console.log(fcmToken);
              console.log("Save token");
              // console.log(me);
              dispatch(
                updateToken({ id: me._id, notification: { token: fcmToken } })
              );
              window.localStorage.setItem("fcmtoken", fcmToken);
            })
            .catch((error) => console.error(error));
        })
        .catch(() => console.log("No se dio permiso"));
    }
  }, [token, me]);

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, showNotification);
    }
  }, []);

  const showNotification = (response) => {
    console.log("Notificación", response);
    alert(`${response.notification.title}\n${response.notification.body}`);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          borderRadius: 0,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" color="inherit" component="div">
            Hola, {me?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <AppDrawerNew isMobile={mobileOpen} onClose={handleDrawerClose} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          <AppDrawerNew />
        </Drawer>
      </Box>
      {/* <AppDrawer open={open} onClose={closeDrawer} /> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar></Toolbar>
        <Box
          width={"100%"}
          minHeight={"92.5vh"}
          maxWidth={"100vw"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box flex={1} flexGrow={1}>
            <Outlet />
          </Box>
          <AppFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
