import { Typography } from "@mui/material";
import { TableRow, TableCell } from "@mui/material";

const { REACT_APP_HOST_URL } = process.env;

const ReportItem = (props) => {
  return (
    <TableRow>
      <TableCell sx={{ textAlign: "center" }}>{props.name}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <a
          href={`${REACT_APP_HOST_URL}/images/reports/${props.path}`}
          target="_blank"
          rel="noreferrer"
        >
          {props.name}
        </a>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography variant="caption" sx={{ display: "block" }}>
          {props.period}
        </Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography variant="caption" sx={{ display: "block" }}>
          {props.reportsInve
            ? new Date(
                `${props.date?.slice?.(0, 10)}T00:00:00`
              ).toLocaleDateString("es-MX")
            : new Date(props.date).toLocaleDateString("es-MX", {
                month: "short",
                day: "2-digit",
                year: "numeric",
              })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ReportItem;
