import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ProjectDesciptionButton() {
  const current = useSelector((state) => state.investments.current);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <div>
      <Button
        sx={{
          bgcolor: "#FFFFFF",
          color: "#0B1F2E",
          textDecoration: "underline",
          textUnderlineOffset: "0.5rem",
          boxShadow: 0,
          height: 25,
          borderRadius: "1rem",
          ":hover": {
            color: "primary",
          },
        }}
        onClick={handleClickOpen}
      >
        Descripción del proyecto
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display={"flex"} alignContent={"center"} alignItems={"center"}>
            <Typography variant="h5" sx={{ flexGrow: "1" }}>
              Descripción
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid2 container>
            <Grid2 size={12}>
              {current[0]?.projectRef?.description
                .split("\n")
                .map((textLine) => {
                  if (textLine.includes("http")) {
                    return (
                      <Link href={textLine} target="_blank" key={textLine}>
                        <Typography variant="body1" component={"p"}>
                          {textLine}
                        </Typography>
                      </Link>
                    );
                  }
                  return (
                    <Typography
                      variant="body1"
                      component={"p"}
                      sx={{ color: "black" }}
                      key={textLine}
                    >
                      {textLine}
                    </Typography>
                  );
                })}
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProjectDesciptionButton;
