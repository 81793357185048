import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReport, getReports } from '../api/reportsApi';
import { setCurrentReport, setInvestorReportsList, setReportsList } from '../slices/reportsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getReportsList = createAsyncThunk('reports/getReportsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getReports(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setReportsList({ reports: data.reports })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getInvertorReportsList = createAsyncThunk('reports/getInvertorReportsList', ({ investorId, projectId, associateInvestmentId }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getReports(token, { associateRef: investorId, projectRef: projectId, associateInvestmentId  })
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setInvestorReportsList({ reports: data.reports })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentReport = createAsyncThunk('reports/getCurrentReport', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getReport(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentReport({ report: data.report })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
