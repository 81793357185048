import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forgotPasswordChange } from "../../actions/authActions";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "100vh",
  },
  bgImage: {
    backgroundImage: "url(/img/IDENEW.png)",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    margin: "15px 0px",
    justifyContent: "right",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    height: "100%",
    width: "auto",
    objectFit: "cover",
    marginBottom: "2rem",
  },
  resize: {
    fontSize: 80,
  },

  buttonr: {
    borderRadius: 40,
    marginRight: 80,
    width: "250px",
    height: "60px",
    marginLeft: 80,
    marginTop: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },

  textfieldr: {
    borderRadius: 40,
    [`& fieldset`]: {
      borderRadius: 80,
    },
  },
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#0B1F2E",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0B1F2E",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      boxShadow: "0px -2px 10px 0px rgba(0,0,0,0.55) inset",
      borderRadius: "1rem",
      borderColor: "#0B1F2E",
    },
    "&:hover fieldset": {
      borderColor: "#0B1F2E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0B1F2E",
    },
  },
});

const Reset = () => {
  const [state, setState] = useState({
    user: "",
    password: "",
    token: "",
    repeatPassword: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userError, setUserError] = useState("");
  const [tokenError, setTokenError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordRepeatError, setPasswordRepeatError] = useState("");

  const resetErrors = () => {
    setTimeout(() => {
      setUserError("");
      setPasswordError("");
      setTokenError("");
      setPasswordRepeatError("");
    }, 2000);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    resetErrors();

    if (state.user === "") {
      setUserError("Usario no valido");
    }
    if (state.password === "") {
      setPasswordError("Constraseña no valida");
    }
    if (state.token === "") {
      setTokenError("Código invalido");
    }
    if (state.password === "") {
      setPasswordError("Ingresa un password");
    }

    if (state.repeatPassword === "") {
      setPasswordRepeatError("Ingresa un password");
    }

    if (state.password !== state.repeatPassword) {
      setPasswordError("Las contraseñas no coinciden");
      setPasswordRepeatError("Las contraseñas no coinciden");
    }

    dispatch(forgotPasswordChange({ ...state, callback: handleLogin }));
  };

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <Box sx={styles.container}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={6} lg={7} sx={styles.form}>
          <Box sx={{ px: { xs: "2rem", md: "5rem" } }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={6} md={6} lg={6}>
                <Box
                  alt="IDE Fund Logo"
                  sx={{
                    height: 200,
                    width: "auto",
                    backgroundImage: "url(/img/IDEGREY.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                  }}
                ></Box>
                {/* <img alt="IDE Fund Logo" src="/img/IDE.png" style={styles.logo} />   */}
              </Grid>

              <Grid
                item
                xs={10}
                container
                justifyContent={"center"}
                padding={2}
                component="form"
                onSubmit={handleSubmit}
              >
                <Grid item xs={11} textAlign="center" marginBottom={2}>
                  <Typography variant="h4">Recupera tu contraseña</Typography>
                </Grid>
                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Usuario</Typography>
                </Grid>

                <CssTextField
                  disabled={false}
                  placeholder="usuario"
                  name="user"
                  value={state.user}
                  onChange={handleChange}
                  error={userError !== "" ? true : false}
                  helperText={userError !== "" ? userError : ""}
                  variant="outlined"
                />

                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Código</Typography>
                </Grid>

                <CssTextField
                  disabled={false}
                  placeholder="Código"
                  name="token"
                  value={state.token}
                  onChange={handleChange}
                  error={tokenError !== "" ? true : false}
                  helperText={tokenError !== "" ? tokenError : ""}
                  variant="outlined"
                />

                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Nueva contraseña</Typography>
                </Grid>

                <CssTextField
                  type={"password"}
                  disabled={false}
                  placeholder="Nueva contraseña"
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                  error={passwordError !== "" ? true : false}
                  helperText={passwordError !== "" ? passwordError : ""}
                  variant="outlined"
                />

                <Grid item xs={12} textAlign="left" marginLeft={1}>
                  <Typography variant="h8">Repetir contraseña</Typography>
                </Grid>

                <CssTextField
                  type={"password"}
                  disabled={false}
                  placeholder="Repetir contraseña"
                  name="repeatPassword"
                  value={state.repeatPassword}
                  onChange={handleChange}
                  error={passwordRepeatError !== "" ? true : false}
                  helperText={
                    passwordRepeatError !== "" ? passwordRepeatError : ""
                  }
                  variant="outlined"
                />

                <Grid item xs={11} textAlign="center">
                  <Button style={styles.buttonr} type="submit">
                    <Typography variant="h6"> Actualizar</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Reset;
