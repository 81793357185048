import React, { useEffect, useState } from "react";
import CardAccordion from "../../../../../../../components/containers/CardAccordion";
import {
  Box,
  Card,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getTenYears } from "../../../../../../../utils/getTenyears";
import {
  currencyFormatter,
  currencyGraphFormatter,
} from "../../../../../../../utils/formatUtils";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ p: 1 }}>
        <Typography
          variant="caption"
          component={"p"}
          fontWeight={"bold"}
          align="center"
        >{`${payload[0].payload.name}`}</Typography>
        {/* <Typography variant="caption" component={"p"} textAlign={"center"}>
          Fecha: {new Date(payload[0].payload.date).toLocaleDateString()}
        </Typography> */}
        <div>
          {payload.map((pld, index) => (
            <Typography
              variant="caption"
              component={"p"}
              textAlign={"center"}
              key={`${pld.value}-${index}`}
            >
              Capital: {currencyFormatter.format(pld.value).split(".")[0]}
            </Typography>
          ))}
        </div>
      </Card>
    );
  }

  return null;
};

function DistributionRecordBarGraph({ data, startProyectYear }) {
  const years = getTenYears(startProyectYear);

  const ages = [
    {
      value: "Todos",
      label: "Todos",
    },
    ...years.map((data) => {
      return {
        value: data,
        label: data.toString(),
      };
    }),
  ];

  const isSmallScreen = useMediaQuery("(min-width:600px)");

  const [myData, setMyData] = useState([]);
  const [selectedAge, setSelectedAge] = useState("Todos");

  const handleData = (myNewData, type) => {
    setMyData(myNewData);
  };

  useEffect(() => {
    if (data.length < 1) {
      return;
    }
    const newData = years.map((year) => {
      const cap = data
        .filter((select) => new Date(select.date).getFullYear() === year)
        .reduce((acum, current) => acum + current.capital, 0);
      console.log(year);
      return {
        name: year.toString(),
        capital: cap ?? 0,
        date: new Date(year, 1, 1).toISOString(),
      };
    });
    console.log(newData);
    handleData(newData.reverse(), selectedAge);
  }, [data]);

  const handleChange = (event) => {
    setSelectedAge(event.target.value);
    if (event.target.value === "Todos") {
      const newData = years.map((year) => {
        const cap = data
          .filter((select) => new Date(select.date).getFullYear() === year)
          .reduce((acum, current) => acum + current.capital, 0);
        return {
          name: year.toString(),
          capital: cap ?? 0,
          date: new Date(year, 1, 1).toISOString(),
        };
      });
      handleData(newData.reverse(), selectedAge);
    } else {
      const mySelectedData = data.filter((element) => {
        const date = new Date(element.date).getFullYear();
        // console.log({ date, selectedDate: event.target.value });
        return date === event.target.value;
      });
      handleData(mySelectedData, event.target.value);
    }
  };

  if (data.length === 0) {
    return (
      <CardAccordion title={"Historial de distribuciones"}>
        <Box display={"flex"} justifyContent={"center"} my={2}>
          <Typography>No hay información disponible.</Typography>
        </Box>
      </CardAccordion>
    );
  }

  return (
    <CardAccordion title={"Historial de distribuciones"}>
      <Box>
        <Box display={"flex"} justifyContent={"flex-end"} px={3}>
          <Box sx={{ minWidth: 200 }}>
            <TextField
              select
              label="Fecha"
              disabled={false}
              value={selectedAge}
              onChange={handleChange}
              sx={{ py: 0 }}
            >
              {ages.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        {myData.length < 1 ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={isSmallScreen ? 200 : 300}
          >
            <Typography>
              Actualmente, no se cuenta con información disponible.
            </Typography>
          </Box>
        ) : (
          <ResponsiveContainer height={isSmallScreen ? 200 : 300}>
            <BarChart
              data={myData}
              margin={{
                top: 15,
                right: 25,
                left: 25,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                position="middle"
                tickFormatter={(label) =>
                  `${currencyGraphFormatter(Number(label ?? 0))}`
                }
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="capital"
                barSize={`${65 / myData.length}%`}
                fill="#747474"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
        <Typography variant="caption" component={"p"} align="end" color="gray">
          Chart con información a la fecha de la transacción
        </Typography>
      </Box>
    </CardAccordion>
  );
}

export default DistributionRecordBarGraph;
