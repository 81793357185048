const { REACT_APP_API_BASE_URL } = process.env;

export const getProjects = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/associates/me/projects?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProject = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/projects/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createProject = (token, project) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/projects', {
    method: 'POST',
    body: JSON.stringify({ project }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateProject = (token, id, project) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/projects/' + id, {
    method: 'PUT',
    body: JSON.stringify({ project }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProjectInterests = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/projects/${id}/payments`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createProjectInterest = (token, id, payment) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/projects/${id}/payments`, {
    method: 'POST',
    body: JSON.stringify({ payments: payment }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadProjectImage = (token, id, file) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('files', file);

  const request = new Request(REACT_APP_API_BASE_URL + '/projects/' + id + '/images', {
    method: 'PUT',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadProjectDescriptionImage = (token, id, file) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('files', file);

  const request = new Request(REACT_APP_API_BASE_URL + '/projects/' + id + '/image', {
    method: 'PUT',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
