import { Box, Divider, Grid, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ProjectsFilter = (props) => {
  return (
    <>
      <Grid container component="form" onSubmit={props.onSubmit}>
        <Grid item xs={11} sm={7} md={5} lg={4}>
          <Box
            width={"100%"}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "2px solid",
              boxShadow: "0px 4px 6px 0px rgba(0,0,0,0.35)",
              borderRadius: "0.7rem",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              type="text"
              name="project"
              value={props.projec}
              onChange={props.onChange}
              placeholder="Busqueda de proyecto"
              inputProps={{ "aria-label": "Búsqueda de proyecto" }}
            ></InputBase>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              onClick={props.onSubmit}
              sx={{ ":hover": { color: "black" } }}
            >
              <SearchIcon />
            </IconButton>
          </Box>

          {/* <input style={styles.resize} type="text" placeholder="Búsqueda de proyecto" aria-label=" Búsqueda de proyecto" aria-describedby="button-addon2" name="project" onChange={props.onChange}></input> */}
        </Grid>
      </Grid>
      <br></br>
    </>
  );
};

export default ProjectsFilter;
