import { combineReducers, configureStore } from "@reduxjs/toolkit";
import alertsSlice from "../slices/alertsSlice";
import authSlice from "../slices/authSlice";
import errorsSlice from "../slices/errorsSlice";
import loadingSlice from "../slices/loadingSlice";
import projectsSlice from "../slices/projectsSlice";
import investorsSlice from "../slices/investorsSlice";
import investmentsSlice from "../slices/investmentsSlice";
import distributionsSlice from "../slices/distributionsSlice";
import interestsSlice from "../slices/interestsSlice";
import reportsSlice from "../slices/reportsSlice";
import currencySlice from "../slices/currencySlice";

import investmentReportsSlice from "../slices/investentsReportsSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  currency: currencySlice,
  alerts: alertsSlice,
  loading: loadingSlice,
  errors: errorsSlice,
  investmentReports: investmentReportsSlice,
  projects: projectsSlice,
  investors: investorsSlice,
  investments: investmentsSlice,
  distributions: distributionsSlice,
  interests: interestsSlice,
  reports: reportsSlice,
});

const store = configureStore({ reducer: rootReducer });

export default store;
