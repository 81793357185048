import React from "react";
import ProjectDetailsInterests from "../../../ProjectDetailsInterests";

function InterestSection() {
  return (
    <section>
      <ProjectDetailsInterests />
    </section>
  );
}

export default InterestSection;
